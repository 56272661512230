import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "p-10 space-y-8 container max-w-4xl"
}
const _hoisted_2 = { class: "text-gray-900 font-semibold text-lg mb-12" }
const _hoisted_3 = { class: "text-gray-900 font-semibold text-lg mb-12" }
const _hoisted_4 = { class: "grid grid-cols-2 gap-y-8 gap-x-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseTextInput = _resolveComponent("BaseTextInput")!
  const _component_BaseCard = _resolveComponent("BaseCard")!
  const _component_BaseDatePicker = _resolveComponent("BaseDatePicker")!
  const _component_BaseSelect = _resolveComponent("BaseSelect")!
  const _component_ButtonLink = _resolveComponent("ButtonLink")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_ActionModal = _resolveComponent("ActionModal")!
  const _component_WorkflowLayout = _resolveComponent("WorkflowLayout")!

  return (_openBlock(), _createBlock(_component_WorkflowLayout, {
    name: _ctx.$t('platform.participant.add'),
    steps: _ctx.steps,
    "current-step": _ctx.step,
    disabled: _ctx.saving,
    onComplete: _ctx.complete,
    onCancel: _ctx.cancel
  }, {
    actions: _withCtx(() => [
      _createVNode(_component_ButtonLink, {
        size: "large",
        to: "/participants",
        color: "ghost",
        disabled: _ctx.saving
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('platform.common.back')), 1)
        ]),
        _: 1
      }, 8, ["disabled"]),
      _createVNode(_component_BaseButton, {
        "right-icon": "arrow-right",
        size: "large",
        color: "success",
        class: "ml-2",
        disabled: _ctx.disableNext,
        loading: _ctx.saving,
        type: "submit",
        "data-dusk": "complete-button"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('platform.common.complete')), 1)
        ]),
        _: 1
      }, 8, ["disabled", "loading"])
    ]),
    default: _withCtx(() => [
      (_ctx.step === 0)
        ? (_openBlock(), _createElementBlock("section", _hoisted_1, [
            _createVNode(_component_BaseCard, null, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('platform.participant.details')), 1),
                _createVNode(_component_BaseTextInput, {
                  modelValue: _ctx.participantId,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.participantId) = $event)),
                  "data-dusk": "participant-id",
                  label: _ctx.$t('platform.participant.id'),
                  error: _ctx.errors.custom_identifier ? _ctx.errors.custom_identifier[0] : null
                }, null, 8, ["modelValue", "label", "error"])
              ]),
              _: 1
            }),
            _createVNode(_component_BaseCard, null, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('platform.common.optional')), 1),
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_BaseDatePicker, {
                    modelValue: _ctx.dateOfBirth,
                    "onUpdate:modelValue": [
                      _cache[1] || (_cache[1] = ($event: any) => ((_ctx.dateOfBirth) = $event)),
                      _cache[2] || (_cache[2] = ($event: any) => (_ctx.validateDate($event)))
                    ],
                    "data-dusk": "date-of-birth",
                    label: _ctx.$t('platform.patient.date-of-birth'),
                    "min-date": _ctx.minDate,
                    clear: "",
                    "max-date": _ctx.yesterday,
                    error: _ctx.errors.date_of_birth ? _ctx.errors.date_of_birth[0] : null
                  }, null, 8, ["modelValue", "label", "min-date", "max-date", "error"]),
                  _createVNode(_component_BaseTextInput, {
                    modelValue: _ctx.postcode,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.postcode) = $event)),
                    "data-dusk": "postcode",
                    label: _ctx.$t('platform.participant.postcode'),
                    placeholder: _ctx.$t('platform.participant.enter-postcode'),
                    error: _ctx.errors.postcode ? _ctx.errors.postcode[0] : null,
                    "is-optional": ""
                  }, null, 8, ["modelValue", "label", "placeholder", "error"]),
                  _createVNode(_component_BaseSelect, {
                    modelValue: _ctx.sex,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.sex) = $event)),
                    "data-dusk": "sex",
                    label: _ctx.$t('platform.patient.sex'),
                    options: _ctx.genderOptions,
                    error: _ctx.errors.sex ? _ctx.errors.sex[0] : null,
                    "is-optional": ""
                  }, null, 8, ["modelValue", "label", "options", "error"]),
                  _createVNode(_component_BaseSelect, {
                    modelValue: _ctx.ethnicityId,
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.ethnicityId) = $event)),
                    "data-dusk": "ethnicity",
                    label: _ctx.$t('platform.patient.ethnicity'),
                    options: _ctx.ethnicityOptions,
                    "is-optional": "",
                    error: _ctx.errors.ethnicity_id ? _ctx.errors.ethnicity_id[0] : null
                  }, null, 8, ["modelValue", "label", "options", "error"])
                ])
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true),
      (_ctx.step === 1)
        ? (_openBlock(), _createBlock(_component_ActionModal, {
            key: 1,
            title: _ctx.$t('platform.participant.added'),
            icon: "check-glyph",
            "is-glyph": "",
            "icon-color": "success",
            stroke: false,
            actions: _ctx.modalActions,
            "show-close": false
          }, null, 8, ["title", "actions"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["name", "steps", "current-step", "disabled", "onComplete", "onCancel"]))
}