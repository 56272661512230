
import { Options, Vue } from 'vue-class-component';
import dayjs from 'dayjs';
import axios, { CancelTokenSource } from 'axios';
import { Ethnicity } from '@/models';
import {
  ActionModal,
  BaseButton,
  BaseCard,
  BaseDatePicker,
  BaseSelect,
  BaseTextInput,
  ButtonLink
} from '@/lib/components';
import WorkflowLayout from '@/lib/layouts/WorkflowLayout.vue';
import { AnonymousParticipantService } from '@/custom/research/services/anonymous-participant.service';
import { EthnicityService } from '@/services/api';
import { Participant } from '@/custom/research/models/participant.model';
import { emptyOption, participantGenderOptions } from '@/constants';
import { IModalAction } from '@/lib';
import { useSessionStore } from '@/stores/session.store';
import { useNotificationStore } from '@/stores/notification.store';

@Options({
  components: {
    BaseSelect,
    BaseDatePicker,
    BaseCard,
    ButtonLink,
    BaseButton,
    WorkflowLayout,
    BaseTextInput,
    ActionModal
  }
})
export default class NewParticipant extends Vue {
  genderOptions = participantGenderOptions();
  sessionStore = useSessionStore();
  notificationStore = useNotificationStore();
  step = 0;
  saving = false;
  errors: { [key: string]: string[] } = {};
  dateOfBirth = '';
  sex = '';
  postcode = '';
  ethnicityId = '';
  participantId = '';
  ethnicityService = new EthnicityService();
  ethnicities: Array<Ethnicity> = [];
  request: CancelTokenSource | null = null;
  participant: Participant | null = null;

  get modalActions(): IModalAction[] {
    return [
      {
        color: 'primary',
        label: this.$t('platform.participant.continue-to-participant'),
        onClick: () => this.$router.push(`/participants/${this.participant?.id}`)
      },
      {
        color: 'secondary',
        label: this.$t('platform.patient.add-another'),
        onClick: () => this.reset()
      },
      {
        color: 'ghost',
        label: this.$t('platform.common.close'),
        onClick: () => this.cancel()
      }
    ];
  }

  get service(): AnonymousParticipantService {
    return new AnonymousParticipantService(this.organisationId);
  }

  get steps(): string[] {
    return [this.$t('platform.participant.details') as string];
  }

  get organisationId() {
    return this.sessionStore.currentOrganisationId;
  }

  get yesterday() {
    return dayjs().subtract(1, 'day').format('YYYY-MM-DD');
  }

  get minDate() {
    return dayjs().subtract(150, 'year').format('YYYY-MM-DD');
  }

  get ethnicityOptions() {
    return [emptyOption(), ...this.ethnicities.map((ethnicity) => ({
      value: ethnicity.id,
      label: ethnicity.title
    }))];
  }

  get disableNext() {
    return !this.participantId.length || this.errorsCount > 0;
  }

  get errorsCount() {
    return Object.values(this.errors).filter((value) => value.length).length;
  }

  reset() {
    // FIXME
    this.$router.go(0);
  }

  async created() {
    await this.fetchEthnicities();
  }

  async fetchEthnicities() {
    try {
      this.request = axios.CancelToken.source();
      this.ethnicities = (
        await this.ethnicityService.index({
          cancelToken: this.request.token
        })
      ).data;
    } catch (e) {
      if (!axios.isCancel(e)) {
        this.notificationStore.addErrorNotification({
          title: this.$t('platform.error.fetch-data')
        });
      }
    } finally {
      this.request = null;
    }
  }

  validateDate(date: string) {
    if (dayjs(date).isAfter(dayjs().subtract(1, 'day'))) {
      this.errors = {
        ...this.errors,
        date_of_birth: [this.$t('platform.patient.dob-error', [this.yesterday]) as string]
      };
    } else if (this.errors.date_of_birth) {
      this.errors = {
        ...this.errors,
        date_of_birth: []
      };
    }
  }

  async complete() {
    this.saving = true;

    try {
      const newParticipant: Partial<Participant> = {
        ...(this.dateOfBirth ? { date_of_birth: this.dateOfBirth } : {}),
        ...(this.sex ? { sex: this.sex } : {}),
        ...(this.postcode ? { postcode: this.postcode } : {}),
        custom_identifier: this.participantId
      };

      if (this.ethnicityId.length) {
        newParticipant.ethnicity_id = this.ethnicityId;
      }
      this.participant = (await this.service.create(newParticipant)).data;
      this.step = 1;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 422) {
          this.errors = error.response.data?.errors;
        } else {
          this.notificationStore.addErrorNotification({
            title: this.$t('platform.participant.saving-error'),
            label: error.response?.data?.message
          });
        }
      }
      // TODO: Handle other errors?
      this.saving = false;
      console.error(error);
    }
  }

  async cancel() {
    await this.$router.push('/participants');
  }
}
